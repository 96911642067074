import React, { useEffect, useRef, useState } from 'react'
import { CloudinaryVideo, Icons } from 'msp-components'
import { useWindowSize } from 'msp-integrations'
import { Equalizer } from '@/components/atoms/Equalizer'
import VideoProgressBar from './partials/VideoProgressBar'
import { VideoStandaloneProps } from './VideoStandalone.types'

const VideoStandalone = ({
  className,
  cloudName,
  publicId,
  publicIdMobile,
  hideFullWidthButton,
  hideMuteButton,
  hidePlayPauseButton,
  hideProgressBar,
  playButtonPosition = 'right',
  disableProgressBarClick,
  autoPlay = true,
  loop = true,
  muted = true,
  showPuroControls,
  children
}: VideoStandaloneProps) => {
  const videoRef = useRef<HTMLVideoElement>()
  const progressBarRef = useRef<HTMLDivElement>()
  const [isPlaying, setIsPlaying] = useState(
    videoRef?.current?.currentTime > 0 &&
      !videoRef?.current?.paused &&
      !videoRef?.current?.ended &&
      videoRef?.current?.readyState > videoRef?.current?.HAVE_CURRENT_DATA
  )
  const [isMuted, setIsMuted] = useState(muted)
  const [videoProgress, setVideoProgress] = useState(0)
  const { width, breakpoints } = useWindowSize()
  const isMobile = width && width < breakpoints.md

  const togglePlay = () => {
    setIsPlaying(!isPlaying)
  }

  const toggleMute = () => {
    !isMobile && setIsMuted(!isMuted)
  }

  const toggleFullScreen = () => {
    videoRef.current.requestFullscreen()
  }

  const handleTimeUpdate = () => {
    if (videoRef?.current) {
      const progressBarPercentage = Math.ceil(
        (videoRef.current.currentTime / videoRef.current.duration) * 100
      )
      progressBarPercentage !== videoProgress &&
        setVideoProgress(progressBarPercentage)
    }
  }

  const handleVideoSkip = (e: MouseEvent) => {
    const skipMoment =
      (e.offsetX / progressBarRef.current.offsetWidth) *
      videoRef.current.duration
    videoRef.current.currentTime = skipMoment
  }

  useEffect(() => {
    if (autoPlay) {
      togglePlay()
    }
  }, [autoPlay])

  useEffect(() => {
    if (showPuroControls && !hideProgressBar) {
      if (videoRef.current) {
        videoRef.current.addEventListener('timeupdate', handleTimeUpdate)
      }
    }

    if (
      showPuroControls &&
      progressBarRef.current &&
      !disableProgressBarClick &&
      !hideProgressBar
    ) {
      progressBarRef.current.addEventListener('mouseup', (e) =>
        handleVideoSkip(e)
      )
    }

    return () => {
      videoRef?.current?.removeEventListener('timeupdate', handleTimeUpdate)
      progressBarRef?.current?.removeEventListener('mouseup', (e) =>
        handleVideoSkip(e)
      )
    }
  }, [showPuroControls])

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying && videoRef.current.paused) {
        videoRef.current
          .play()
          .then((_) => {})
          .catch((error) => {})
      } else {
        videoRef.current.pause()
      }
    }
  }, [isPlaying])

  useEffect(() => {
    if (videoRef.current) {
      if (isMuted) {
        videoRef.current.volume = 0
        videoRef.current.muted = true
      } else {
        videoRef.current.volume = 0.7
        videoRef.current.muted = false
      }
    }
  }, [isMuted])

  return (
    <div
      className={`relative h-full w-full transition-all duration-500 ease-in ${className}`}
    >
      <CloudinaryVideo
        ref={videoRef}
        className={'object-cover'}
        cloudName={cloudName}
        publicId={isMobile && publicIdMobile ? publicIdMobile : publicId}
        controls={!showPuroControls}
        loop={loop}
        muted={muted || isMobile}
        preload={'none'}
      />
      <div
        className={`absolute flex flex-col justify-items-center fill-white text-white ${
          showPuroControls ? 'inset-0' : 'left-0 top-0 h-4/5 w-full'
        }`}
      >
        <div
          className={
            'text-44 tracking-1 leading-56 md:text-60 md:leading-72 md:tracking-2 flex h-full w-full select-none flex-col items-center justify-around self-center overflow-hidden text-center md:w-[40vw]'
          }
        >
          {children}
        </div>
        {showPuroControls && (
          <div
            className={
              'flex h-auto w-full items-center space-x-4 stroke-white stroke-2 px-8 py-6 md:space-x-6'
            }
          >
            {!hidePlayPauseButton && playButtonPosition === 'left' && (
              <div
                onClick={() => togglePlay()}
                className={'flex-none cursor-pointer'}
              >
                <Icons
                  iconName={isPlaying ? 'Pause' : 'Play'}
                  height={'25px'}
                  width={'25px'}
                />
              </div>
            )}
            {!hideMuteButton && (
              <div
                onClick={() => toggleMute()}
                className={'flex-none cursor-pointer'}
              >
                {!isMuted ? (
                  <Equalizer className={'h-7 w-7'} />
                ) : (
                  <Icons
                    iconName={'SoundMute'}
                    height={'25px'}
                    width={'25px'}
                  />
                )}
              </div>
            )}
            <div className={'flex flex-auto justify-center'}>
              {!hideProgressBar && (
                <VideoProgressBar
                  ref={progressBarRef}
                  percentage={videoProgress}
                  clickable={!disableProgressBarClick}
                />
              )}
            </div>
            {!hidePlayPauseButton && playButtonPosition === 'right' && (
              <div
                onClick={() => togglePlay()}
                className={'flex-none cursor-pointer'}
              >
                <Icons
                  iconName={isPlaying ? 'Pause' : 'Play'}
                  height={'25px'}
                  width={'25px'}
                />
              </div>
            )}
            {!hideFullWidthButton && (
              <div
                onClick={() => toggleFullScreen()}
                className={'flex-none cursor-pointer'}
              >
                <Icons
                  iconName={'VideoExpand'}
                  height={'25px'}
                  width={'25px'}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoStandalone
